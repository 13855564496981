<template>
  <AppPage :show-menu="true" :class="{centered: true}" :showFooter="false">
    <div class="container">
      <div style="text-align: center;">
        <h1>Need help?</h1>
        <h2>Search our knowledge base.</h2>
        <div class="search-input">
          <InputText placeholder="Search" @change="query=$event"/>
          <Button class="special uppercase small" icon="magnifying-glass"></Button>
        </div>
      </div>
      <div v-if="query" >
        <h2>Thanks for your input!</h2>
        <p>As our knowledge base is under construction, your search helps us better understand your needs. Thank you for participating and driving us forward.</p>
        <p>If you need direct support, please <a href="#" @click="openMessenger">send us a message</a>.</p>
      </div>
      <br />
      <br />
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import AppPage from "@/components/organisms/AppPage.vue";
import Button from "@/components/atoms/Button.vue";
import InputText from "@/components/atoms/InputText.vue";

export default Vue.extend({
  components: {
    InputText,
    Button,
    AppPage,
  },
  data(){
    return {
      query: ''
    }
  },
  created(){
    this.$tawkMessenger.maximize();
  },
  beforeDestroy() {
    this.$tawkMessenger.minimize();
  },
  methods: {
    openMessenger(){
      this.$tawkMessenger.maximize();
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';
.container{
  width: 550px;
}
.search-input{
  display: flex;
  gap: 0;
}
input, button {
  width: 100%;
  margin: 0;
}
button {
  width: 5em;
  padding: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
input{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
